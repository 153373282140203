import type DataObject from 'o365.modules.DataObject.ts';
import type { DataItemModel, ItemModel } from 'o365.modules.DataObject.Types.ts';
import BulkOperation from 'o365.modules.utils.BulkOperation.ts';
import { addEventListener } from 'o365.vue.composables.EventListener.ts';
import { userSession } from 'o365.modules.configs.ts';
import $t from 'o365.modules.translate.ts';

export default class CalendarGridControl<T extends ItemModel = ItemModel> {
    private _props: CalendarGridProps<T>;
    private _models: CalendarGridModels;
    private _initialized = false;
    private _cleanupTokens: (() => void)[] = [];

    private _bodyContainer?: HTMLElement;
    private _headerContainer?: HTMLElement;

    private _updated: Date = new Date();

    private _bulkLoadItems = new BulkOperation<CalendarColumn<T>, DataItemModel<T>[]>({
        bulkOperation: async (pItems) => {
            let lowestDate: Date | undefined = undefined;
            let highestDate: Date | undefined = undefined;
            pItems.forEach(item => {
                if (lowestDate == null || item.value.startDate < lowestDate) {
                    lowestDate = item.value.startDate;
                }
                if (highestDate == null || item.value.endDate > highestDate) {
                    highestDate = item.value.endDate;
                }
            });
            if (lowestDate == null || highestDate == null) {
                pItems.forEach(item => item.rej(new TypeError('Could not get date range for loading items')));
                return;
            }
            let filterString = `[${this.bindingField}] >= '${(lowestDate as Date).toISOString()}' AND [${this.bindingField}] <= '${(highestDate as Date).toISOString()}'`;
            if (this.dataObject.recordSource.filterString) {
                filterString = `(${filterString}) AND (${this.dataObject.recordSource.filterString})`;
            }
            const data = await this.dataObject.recordSource.refreshRowsByFilter(filterString);
            pItems.forEach(item => {
                const dataItems = data.filter(x => x && item.value.dateIsInRange(x[this.bindingField])) as DataItemModel<T>[];
                item.res(dataItems);
            });
        }
    });

    private _columns: CalendarColumn<T>[] = [];

    get props() { return this._props; }
    get bindingField() { return this._props.bindingField; }

    get dataObject() { return this._props.dataObject; }

    get columns() { return this._columns; }

    get updated() { return this._updated; }


    get steps(): ['year', 'month', 'week', 'day'] { return ['year', 'month', 'week', 'day']; }

    get viewModeIndex() { return this.steps.indexOf(this.viewMode); }

    get startDate() { return this._models.startDate; }
    set startDate(pValue) { this._models.startDate = pValue; }

    get endDate() { return this._models.endDate; }
    set endDate(pValue) { this._models.endDate = pValue; }

    get viewMode() { return this._models.viewMode; }
    set viewMode(pValue) { this._models.viewMode = pValue; }

    get draggable() {
        return true;
    }

    constructor(pProps: CalendarGridProps<T>, pModels: CalendarGridModels) {
        this._props = pProps;
        this._models = pModels;

        console.log(this._models)
    }

    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;

        this.dataObject.createNewAtTheEnd = true;

        if (this._props.fieldFilters) {
            this.dataObject.filterObject.setFieldFilters(this._props.fieldFilters);
        }

        this._cleanupTokens.push(this.dataObject.on('BeforeLoad', (pOptions => {
            pOptions.cancelEvent = true;
            this.load();
        })));

        if (this.dataObject.metadata?.isFromDesigner) {
            this.dataObject.filterObject.persistentFilterId = `calendar-${this.dataObject.id}`;
        }

        this.load();
    }

    initializeContainer(pOptions: {
        body: HTMLElement,
        header: HTMLElement
    }) {
        this._bodyContainer = pOptions.body;
        this._headerContainer = pOptions.header;

        let scrollOrigin: 'header' | 'body' | '' = '';
        let scrollOriginDebounce: number | null = null;
        const updateScrollOrigin = (pOrigin: typeof scrollOrigin) => {
            scrollOrigin = pOrigin;
            if (scrollOriginDebounce) { window.clearTimeout(scrollOriginDebounce); }
            scrollOriginDebounce = window.setTimeout(() => {
                scrollOrigin = '';
                scrollOriginDebounce = null;
            }, 100);
        }

        this._cleanupTokens.push(addEventListener(this._bodyContainer, 'scroll', (pEvent) => {
            if (this._headerContainer == null || scrollOrigin === 'body') { return; }
            updateScrollOrigin('header');
            this._headerContainer.scrollLeft = (pEvent.target as HTMLElement).scrollLeft;
        }, { passive: true }));
        this._cleanupTokens.push(addEventListener(this._headerContainer, 'scroll', (pEvent) => {
            if (this._bodyContainer == null || scrollOrigin === 'header') { return; }
            updateScrollOrigin('body');
            this._bodyContainer.scrollLeft = (pEvent.target as HTMLElement).scrollLeft;
        }, { passive: true }));
    }

    destroy() {
        this._cleanupTokens.forEach(ct => ct());
    }

    load() {
        if (this.startDate == null || this.endDate == null) {
            return;
        }
        const columns = this._getColumns({
            step: this.viewMode,
            startDate: getDateWithoutTime(this.startDate),
            endDate: getDateWithoutTime(this.endDate)
        });
        this._columns.splice(0, this._columns.length, ...columns);
        if (!this._props.disableCurrentIndexSetOnLoad) {
            if (this._columns[0]?.isLoading && this._columns[0].loadingPromise) {
                this._columns[0].loadingPromise.then((data) => {
                    this.dataObject.setCurrentIndex(data[0].index);
                })
            }
        }
        this.update();
    }

    retrieveItemsForColumn(pColumn: CalendarColumn<T>) {
        return this._bulkLoadItems.addToQueue(pColumn);
    }

    setScrollPositino(pPosition: number) {
        if (this._bodyContainer) {
            this._bodyContainer.scrollLeft = pPosition;
        }
    }

    private _getColumns(pOptions: {
        step: 'day' | 'week' | 'month' | 'year'
        startDate: Date,
        endDate: Date
    }) {

        const columns: CalendarColumn<T>[] = [];
        let currentDate = new Date(pOptions.startDate);
        const addStep = {
            day: (pDate = currentDate) => pDate.setDate(pDate.getDate() + 1),
            week: (pDate = currentDate) => {
                const daysToAdd = ((8 - pDate.getDay()) % 7) || 7;
                pDate.setDate(pDate.getDate() + daysToAdd);
            },
            month: (pDate = currentDate) => pDate.setMonth(pDate.getMonth() + 1),
            year: (pDate = currentDate) => pDate.setFullYear(pDate.getFullYear() + 1)
        }[pOptions.step];

        const getDisplayValue = (pDate: Date, pStep?: typeof pOptions['step']) => {
            switch (pStep ?? pOptions.step) {
                case 'day':
                    return `${userSession.dayNamesShort?.[pDate.getDay()]} ${pDate.getDate()}`;
                case 'week':
                    return $t('W') + this._getISOWeekNumber(pDate);
                case 'month':
                    return `${userSession.monthNames?.[pDate.getMonth()] ?? pDate.getMonth()}`;
                case 'year':
                    return `${pDate.getFullYear()}`;
            }
        };

        while (currentDate <= pOptions.endDate) {
            // Next step
            const nextStepDate = new Date(currentDate);
            addStep(nextStepDate);
            columns.push(new CalendarColumn<T>({
                bindingValue: [new Date(currentDate), nextStepDate],
                display: {
                    day: getDisplayValue(currentDate, 'day'),
                    week: getDisplayValue(currentDate, 'week'),
                    month: getDisplayValue(currentDate, 'month'),
                    year: getDisplayValue(currentDate, 'year'),
                },
                getControl: () => this
            }));
            addStep();
        }

        const widthIndexes: DateSteps<number> = {
            day: 0,
            week: 0,
            month: 0,
            year: 0
        };


        columns.forEach((column, index) => {
            let previousColumn = columns[index - 1];
            column.stepHeaders.day = 'day' === this.viewMode;
            column.stepHeaders.week = 'week' === this.viewMode || !previousColumn || previousColumn.display.week !== column.display.week;
            column.stepHeaders.month = 'month' === this.viewMode || !previousColumn || previousColumn.display.month !== column.display.month;
            column.stepHeaders.year = 'year' === this.viewMode || !previousColumn || previousColumn.display.year !== column.display.year;
            this.steps.forEach(step => {
                if (step === 'day') { return; }
                if (column.stepHeaders[step]) {
                    widthIndexes[step] = index;
                }
                const startIndex = widthIndexes[step];
                for (let i = startIndex; i <= index; i++) {
                    columns[i].widths[step] = 300 * (index - startIndex + 1);
                    columns[i].leftAdjustments[step] = 300 * (i - startIndex);
                }
            });
        });

        return columns;
    }

    setViewMode(pMode: typeof this.viewMode) {
        this.viewMode = pMode;
        this.setScrollPositino(0);
        this.load();
    }

    update() {
        this._updated = new Date();
    }

    private _getISOWeekNumber(pDate: Date | string) {
        const date = new Date(pDate) as any;
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 4 - (date.getDay() || 7));
        const yearStart = new Date(date.getFullYear(), 0, 1) as any;
        return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    }
}

class CalendarColumn<T extends ItemModel = ItemModel> {
    private _isLoaded = false;
    private _items: DataItemModel<T>[] = [];
    private _bindingValue: [Date, Date];
    private _display: DateSteps<string>;
    private _widths: DateSteps<number> = {
        day: 300,
        week: 0,
        month: 0,
        year: 0
    };
    private _leftAdjustments: DateSteps<number> = {
        day: 0,
        week: 0,
        month: 0,
        year: 0
    };
    private _stepHeaders: DateSteps<boolean> = {
        day: false,
        week: false,
        month: false,
        year: false
    };
    private _getControl: () => CalendarGridControl<T>;
    private _loadingPromise?: Promise<DataItemModel<T>[]>;

    get isLoading() {
        if (!this._isLoaded) {
            this._loadItems();
            return true;
        } else {
            return false;
        }
    }
    get loadingPromise() { return this._loadingPromise; }
    get display() { return this._display; }

    get widths() { return this._widths; }
    get stepHeaders() { return this._stepHeaders; }
    get leftAdjustments() { return this._leftAdjustments; }

    get items() { return this._items; }

    get startDate() { return this._bindingValue[0]; }
    get endDate() { return this._bindingValue[1]; }

    constructor(pOptions: {
        bindingValue: [Date, Date],
        display: DateSteps<string>,
        getControl: () => CalendarGridControl<T>
    }) {
        this._bindingValue = pOptions.bindingValue;
        this._display = pOptions.display;
        this._getControl = pOptions.getControl;
    }

    dateIsInRange(pDate: Date | string) {
        if (typeof pDate === 'string') { pDate = new Date(pDate); }
        return pDate >= this.startDate && pDate < this.endDate;
    }

    private async _loadItems() {
        if (this._loadingPromise != null) { return; }
        const control = this._getControl();
        this._loadingPromise = control.retrieveItemsForColumn(this) as Promise<DataItemModel<T>[]>;
        const items = await this._loadingPromise;
        this._items.splice(0, this._items.length, ...items);
        this._isLoaded = true;
    }

}

function getDateWithoutTime(pDate: Date | string) {
    if (typeof pDate === 'string') {
        return new Date(pDate);
    }
    const formatDoubleDigits = (pNumber: number) => String(pNumber).padStart(2, '0');
    return new Date(`${pDate.getFullYear()}-`
        + `${formatDoubleDigits(pDate.getMonth() + 1)}-`
        + `${formatDoubleDigits(pDate.getDate())}`
        + 'T00:00:00');
}

type DateSteps<T> = {
    day: T,
    week: T,
    month: T,
    year: T
};

type CalendarGridProps<T extends ItemModel = ItemModel> = {
    dataObject: DataObject<T>,
    bindingField: string,
    fieldFilters?: string[],
    disableCurrentIndexSetOnLoad?: boolean,
};

type CalendarGridModels = {
    startDate: Date,
    endDate: Date,
    viewMode: 'day' | 'week' | 'month' | 'year'
};